export enum DocumentPageSize {
	A4 = 'A4',
	foto = '10x15',
}

export enum DocumentStatus {
	error = -1, // Документ не может быть обработан
	waiting = 0, // Документ зарегисрирован, но ещё не начал обрабатываться
	processed = 1, // Документ готов к печати
	pending = 2, // Конвертация запущена, ждем конца
}

export enum ScanOperationStatus {
	scanWaiting = 1000, // Ожидает сканирование в очереди
	scanning = 1001, // Сканируется
	convertWaiting = 1002, // Обрабатывается
	converting = 1003, // Сохраняется
	successConverting = 1004, // Успешно отсканирован
	failConverting = 1005, // Ошибка
}

export const UploadingOperationStatus = -3 as const;

export type Document = {
	pk: number;
	name: string;
	created_at: string;
	pdf_url: string;
	status_name: string;
	status: DocumentStatus;
	color_gs: number;
	color_c_average: number;
	color_m_average: number;
	color_y_average: number;
	color_k_average: number;
	color_gs_average: number;
	color_cmyk_average: number;
	color_average: number;
	pages_count: number;
	preview_warning: boolean;
	preview_size: number;
	n_up_option_x: number;
	n_up_option_y: number;
	batch_id: number | null;
	page_size: DocumentPageSize;
	error_info: DocumentErrorInfo | null;
}

export enum DocumentErrorInfo {
	CONVERSION_ERROR_TIMEOUT = 'CONVERSION_ERROR_TIMEOUT',
	CONVERSION_ERROR_MAX_FILE_SIZE_REACHED = 'CONVERSION_ERROR_MAX_FILE_SIZE_REACHED',
	UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export type ScanningDocument = {
	pk: number; // operation_document,
	name: string;
	created_at: string;
	pdf_url: null;
	status_name: string;
	status: ScanOperationStatus;
	color_gs: 0;
	pages_count: 1;
	preview_warning: false;
	preview_size: 0;
	operation_id: number;
}

export type UploadingDocument = {
	status: typeof UploadingOperationStatus;
	name: string;
	size: number;
	uploadedSize: number;
	uploadingId: string;
}

export type AnyDocument = Document | ScanningDocument | UploadingDocument;

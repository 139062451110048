import { FC, useState } from 'react';
import block from 'utils/bem-css-module';

import { Button } from 'components/Button';
import { Checkbox } from 'components/Checkbox';

import style from './documents.scss';

const b = block(style);


type Props = {
	onDeleteButtonClick: () => void;
	onSelectAllClick: (checked: boolean) => void;
	onPrintButtonClick: () => void;
	selectedDocumentPks?: Set<number>;
	onCancel: () => void;
	disabled: boolean;
}

export const DocumentsButtons: FC<Props> = ({
	onDeleteButtonClick,
	onPrintButtonClick,
	onSelectAllClick,
	selectedDocumentPks,
	onCancel,
	disabled,
}) => {
	const [selectedAll, setSelectedAll] = useState(false);

	const onCheckboxClick = () => {
		setSelectedAll((selectedAll) => !selectedAll);
		onSelectAllClick(!selectedAll);
	};
	
	return (
		<div className={b('buttons', { disabled })}>
			<Button
				buttonType='success'
				size='s'
				onClick={onPrintButtonClick}
				disabled={selectedDocumentPks ? selectedDocumentPks.size === 0 : false}
				className={b('next')}
			>
				{selectedDocumentPks ? `Выбрать (${selectedDocumentPks.size})`: 'Далее' }
			</Button>
			{selectedDocumentPks ?
				<>
					<Button
						buttonType='default'
						onClick={onCancel}
						size='s'
					>
						Отменить
					</Button>
					<Checkbox 
						onClick={onCheckboxClick}
						checked={selectedAll} 
						id='documents-button-select-all-checkbox'
						size='s'
						className={b('select-all-checkbox')}
					/>
				</>
				:
				<Button
					buttonType='danger'
					className={b('button-delete')}
					onClick={onDeleteButtonClick}
					size='s'
				>
					Удалить всё
				</Button>
			}
		</div>
	);
};

import { memo, ReactElement } from 'react';
import block from 'utils/bem-css-module';

import { ScanOperation } from 'types/operation';

import { CurrentOperationsIcon } from './current-opetaions-icon';

import style from './current-operations.scss';


type CurrentOperationsProps = {
	operations: (ScanOperation)[];
	onClick: () => void;
}

const b = block(style);

function CurrentOperations({
	operations,
	onClick,
}: CurrentOperationsProps): ReactElement {
	return (
		<div className={b()}>
			{operations.map((operation) => (
				<div
					className={b('operation')}
					key={operation.id}
					onClick={onClick}
				>
					<CurrentOperationsIcon operation={operation}/>
				</div>
			))}
		</div>
	);
}

const CurrentOperationsMemo = memo(CurrentOperations);
export { CurrentOperationsMemo as CurrentOperations };

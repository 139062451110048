import { FC } from 'react';
import block from 'utils/bem-css-module';
import { getDocumentName } from 'utils/document';

import { Ellipsis } from 'components/Ellipsis';
import { Paragraph } from 'components/Paragraph';

import { UploadingDocument } from 'types/document';

import { DocumentEntityIcon } from './document-entity-icon';
import { DocumentProgress } from './document-progress';

import style from './document-entity.scss';

const b = block(style);

type Props = {
    doc?: UploadingDocument;
}

export const DocumentUploadingEntity: FC<Props> = ({
	doc,
}) => {
	const loading = !doc;
	return (
		<div className={b()}>
			<DocumentEntityIcon status={doc?.status} />
			<article className={b('info')}>
				<p className={b('title', { loading })}>
					{doc ? getDocumentName(doc.name) : ''}
				</p>
				{doc ? 
					<DocumentProgress size={doc.size} loadedSize={doc.uploadedSize} /> :
					<Paragraph size='xs' className={b('description',  { loading: true })}/>
				}
			</article>
			<Ellipsis loading={true} disabled={true} className={b('triplet')} />
		</div>
	);
};

import { memo, ReactElement } from 'react';
import block from 'utils/bem-css-module';

import { CheckMark, DocumentLoading, ErrorIcon } from 'components/Icon';
import { ReactComponent as DocumentType } from 'components/Icon/svg/document-type.svg';
import { Loader } from 'components/Loader';

import { DocumentStatus, ScanOperationStatus } from 'types/document';
import { ScanOperation } from 'types/operation';

import style from './current-operations.scss';

type CurrentOperationsIconProps = {
	operation: ScanOperation;
}

const b = block(style);

function CurrentOperationsIcon({
	operation,
}: CurrentOperationsIconProps): ReactElement {
	if (ScanOperationStatus.scanWaiting === operation.status) {
		return (
			<Loader className={b('loader')} />
		);
	}
	if ([
		ScanOperationStatus.scanning,
		ScanOperationStatus.scanWaiting,
	].includes(operation.status)) {
		return (
			<div className={b('scan-icon')}>
				<DocumentType />
			</div>
		);
	}

	if ([
		ScanOperationStatus.converting,
		ScanOperationStatus.convertWaiting,
	].includes(operation.status)) {
		return (
        	<DocumentLoading />
		);
	}

	if ([ScanOperationStatus.failConverting, DocumentStatus.error].includes(operation.status)) {
		return (
			<ErrorIcon />
		);
	}

	if ([ScanOperationStatus.successConverting].includes(operation.status)) {
		return (
        	<div className={b('success-icon')}>
				<DocumentType />
				<CheckMark className={b('check')}/>
			</div>
		);
	}
	return <div />;
}

const CurrentOperationsIconMemo = memo(CurrentOperationsIcon);
export { CurrentOperationsIconMemo as CurrentOperationsIcon };

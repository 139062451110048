import { Link } from 'components/Link';
import { Paragraph } from 'components/Paragraph';


export const AgreementBlock = () => {
	return (
		<Paragraph size='xs'>
			Загружая файл, вы соглашаетесь с&nbsp;нашими&nbsp;<Link url='/agreement'>
			условиями использования</Link> и <Link url='/policy'>политикой конфиденциальности</Link>
		</Paragraph>
	);
};

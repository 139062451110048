import { FC } from 'react';
import block from 'utils/bem-css-module';

import { Ellipsis, EllipsisPopup } from 'components/Ellipsis';

import { DocumentStatus, ScanningDocument, ScanOperationStatus } from 'types/document';
import { Document } from 'types/document';

import { DocumentEntityPopupItems } from './document-entiry-popup-items';

import style from './document-entity.scss';
const b = block(style);

type Props = {
    doc: Document | ScanningDocument;
	setDeletedDoc: (pk: number) => void;
	showErrorInfo: () => void;
}

export const DocumentEntityActions: FC<Props> = ({
	doc,
	setDeletedDoc,
	showErrorInfo,
}) => {
	if ([DocumentStatus.error, ScanOperationStatus.failConverting, DocumentStatus.processed].includes(doc.status)) {
		return (
			<>
				<EllipsisPopup loading={false} disabled={false} className={b('triplet')}>
					<DocumentEntityPopupItems
						doc={doc}
						setDeletedDoc={setDeletedDoc}
						showErrorInfo={showErrorInfo}
					/>
				</EllipsisPopup>
			</>
		);
	}
	return (
		<Ellipsis
			id={`document-entity-triplet-${doc.pk}`}
			loading={true}
			disabled={true}
			className={b('triplet')}
		/>
	);
};

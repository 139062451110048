import { getColorOption } from 'utils/point';

import { Show } from 'types/map';


export function findURLParam(param: string, url = window.location.href ) {
	let value: string | null | undefined;
	try {
		value = new URL(url).searchParams.get(param);
	} catch {
		value = new URL(url, 'http://localhost').searchParams.get(param);
	}
	return value;
}

export function getDocumentPksFromURL(url: string) {
	const param = findURLParam('documents', url);
	if (param === null || param === undefined) {
		return null;
	}
	return param.split(',').map(Number);
}

/**
 * Возвращает url страницы настроек заказа, в зависимости от урла карты
 */
export function getSettingsPrintURL(
	documentPks: number[],
	show = Show.all,
): string {
	return (`/print/settings?documents=${documentPks.join(',')}${show === Show.all ? '' : `&color_option=${getColorOption(show)}`}`);
}

/**
 * Returns url for map based on documents' pks
 * @param documentPks
 */
export function getPrintMapURL(documentPks: number[]) {
	if (documentPks.length === 0) {
		return '/print/map/';
	}
	return `/print/map/?documents=${documentPks.join(',')}`;
}

export function getDocumentPksFromUrl(url: string) {
	return findURLParam('documents', url)?.split(',') || [];
}

/**
 * Checks if the QR with the default point is valid
 * @param url
 */
export function isValidQR(url: string) {
	const SBER_ORIGIN = 'https://sb.printbox.io';
	return new RegExp(`(${window.location.origin}|${SBER_ORIGIN})/\\?default_point_id=`).test(url);
}

export function getUrlWithParams(url: string, params: Record<string, string | number | undefined | (string|number)[]>) {
	const urlObj = new URL(url);
	const searchParams = urlObj.searchParams;
	for (const key in params) {
		const value = params[key];
		if (value === undefined) {
			continue;
		}
		searchParams.set(key, Array.isArray(value) ? value.join(',') : value.toString());
	}
	return urlObj.href;
}

export function getPath(url: string) {
	const urlObj = new URL(url);
	const search = urlObj.searchParams.toString();
	return urlObj.pathname + (search ? `?${search}` : '');
}

/**
 * Problem: yandex metric can not detect redirects from our scanner and count them as direct visits
 * Solution: add parameters to url to show a source
 * --
 * Scanner of printbox
 * utm_source=qr_scanner_at_printboxio
 * utm_medium=qr
 * --
 * Scanner of sb.printbox
 * utm_source=qr_scanner_at_sbprintboxio
 * utm_medium=qr
 * @param url
 */
export function addQrParamsForTracking(url: string):string {
	const urlObj = new URL(url);
	const source = (window.location.host).split('.').join('');
	urlObj.searchParams.set('utm_source', `qr_scanner_at_${source}`);
	urlObj.searchParams.set('utm_medium', 'qr');
	return urlObj.href;
}

export function getPrintOperationsUrl(documentPks: number[], origin = window.location.origin) {
	const searchParams =  documentPks.length ? `?documents=${documentPks.join(',')}`: '';
	return `${origin}/print_operations/${searchParams}`;
}

export function removeDocumentFromUrl(documentPk: number, url: string) {
	const pks = getDocumentPksFromUrl(url).filter(pk => pk !== documentPk.toString());
	const newUrl = new URL(url);
	if (pks.length === 0) {
		newUrl.searchParams.delete('documents');
	} else {
		newUrl.searchParams.set('documents', pks.join(','));
	}
	const searchParams = newUrl.searchParams.toString() ? '?' + newUrl.searchParams.toString() : '';
	return newUrl.pathname + searchParams;
}

export function getYandexReviewsUrl(yandexMapId: string) {
	return `https://yandex.ru/maps/org/printbox/${yandexMapId}/reviews/?add-review=true`;
}

export function geyYandexMapUrl(yandexMapId: string) {
	return `https://yandex.ru/maps/org/printbox/${yandexMapId}/`;
}


export function addPointIdToUrl(urlStr: string, default_point_id: number) {
	const url = new URL(urlStr);
	url.searchParams.append('default_point_id', default_point_id.toString());
	return url.toString();
}

import { sorterPrintersByPrice } from 'utils/price';

import { DEFAULT_PRINT_OPTIONS_FOR_PRINTER } from 'constants/default';
import { PRINTER_STATUSES } from 'constants/printer-status';

import { Show, StatusShow } from 'types/map';
import { OptionsColorType, OptionsDoublePageType, Point, Printer, PrinterStatus } from 'types/point';
import { PrintOptionsForPrinter } from 'types/print';

import { withActiveScanners } from './scanner';

export function withActivePrinters(items: Printer[],
	addProps = {
		paperCount: 1,
		color: OptionsColorType.bw,
		isStaff,
	}): boolean {
	return items.length > 0 && items.some(({ status, paper_count, printer_options_color }) => {
		return(
		     addProps.isStaff ||
             status === PrinterStatus.active &&
             (paper_count >= addProps.paperCount) &&
             (printer_options_color.some(({ cup_name }) => {
             	return cup_name === addProps.color;
			 }))
		);
	});
}

/**
 * Честно возвращает активна ли точка - есть работующие устройства удовлетворяющие Show
 * Внезависимости на isStaff
 * @param point
 * @param show
 */
export function isActive(point: Point, show: Show): boolean {
	if (show === Show.all) {
		return withActiveScanners(point.scanners, false) || checkIsPointWorking(point);
	}
	if (show === Show.scanners) {
		return withActiveScanners(point.scanners, false);
	}
	if ([Show.colorfulCopy, Show.bwCopy].includes(show)) {
		return withActiveScanners(point.scanners, false) && getPrintersByColorOption(getColorOption(show), point.printers).length > 0;
	}
	return getPrintersByColorOption(getColorOption(show), point.printers).length > 0;
}

export function isActiveByStatusShow(statusShow: StatusShow) {
	return Object.values(statusShow).some(Boolean);
}

export function getPrintersByColorOption(
	option: OptionsColorType,
	printers: Printer[],
	all = false,
): Printer[] {
	return printers.filter(({ printer_options_color, status }) => (
		printer_options_color.some(({ cup_name }) => cup_name === option)
		&& (status === PrinterStatus.active || all)
	)).sort((p1, p2)=> {
		const sortByPrice = sorterPrintersByPrice(option)(p1, p2);
		if (sortByPrice === 0) {
			if (p1.printer_options_color.find(({ cup_name }) => cup_name === OptionsColorType.colorful)) {
				return 1;
			}
			return -1;
		}
		return sortByPrice;
	});
}

export function getPrintersByPaperCount(option: OptionsColorType, { printers }: Point): Printer[] {
	return printers.filter(({ printer_options_color, status }) => (
		printer_options_color.some(({ cup_name }) => cup_name === option) && status === PrinterStatus.active),
	).sort((a, b) => {
		return (b.paper_count - a.paper_count);
	});
}

export function getBWPrinter(point: Point, all = false): Printer | undefined {
	return getPrintersByColorOption(OptionsColorType.bw, point.printers, all)[0];
}

export function getColorfulPrinter(point: Point, all = false): Printer | undefined {
	return getPrintersByColorOption(OptionsColorType.colorful, point.printers, all)[0];
}

export function checkIsPointWorking(point?: Point): boolean {
	if (!point) {
		return false;
	}
	const color_printer = getColorfulPrinter(point);
	const bw_printer = getBWPrinter(point);
	return !!(bw_printer || color_printer);
}

export function filterByPrintOptionsForPrinter(printer: Printer,  documentPageSize: number, form: PrintOptionsForPrinter) {
	return (
		printer.printer_options_color.some(({ cup_name }) => cup_name === form.color_option) &&
		printer.printer_options_double_page.some(({ pk }) => pk === form.duplex_option) &&
		printer.paper_count >= getPageCount(documentPageSize, form.copies || 1, form.duplex_option)
	);
}

export function getAvailablePrinters(
	printers: Printer[],
	documentPageSize: number,
	form: PrintOptionsForPrinter = DEFAULT_PRINT_OPTIONS_FOR_PRINTER,
	isStaff = window.isStaff,
): (Printer | never)[] {
	return printers.filter((printer) =>
		isStaff || (printer.status === PrinterStatus.active && filterByPrintOptionsForPrinter(printer, documentPageSize, form)),
	).sort(sorterPrintersByPrice(form.color_option));
}

export function getPageCount(count: number, copies: number, duplexOption: OptionsDoublePageType) {
	if (duplexOption === OptionsDoublePageType.singleSide) {
		return count * copies;
	}
	const pagesPerDocument = Math.ceil(count / 2);
	return pagesPerDocument * copies;
}

/**
 * Соотношение между Show и OptionsColorType
 * @param show
 */
export function getColorOption(show: Show): OptionsColorType {
	switch (show) {
		case Show.colorfulCopy: return OptionsColorType.colorful;
		case Show.colorfulPrinters: return OptionsColorType.colorful;
		case Show.bwCopy: return OptionsColorType.bw;
		case Show.bwPrinters: return OptionsColorType.bw;
		default: return OptionsColorType.bw;
	}
}

export function getPointHeaderForMap(isAvailable: boolean) {
	if (isAvailable) {
		return 'Пункт печати';
	}
	return 'Пункт печати недоступен';
}

export function getStatusCodeByName(name: string) {
	return (
		PRINTER_STATUSES.find((status) => status.name === name)?.code 
		|| PrinterStatus.active
	);
}

export function getStatusNameByCode(code: PrinterStatus) {
	return (
		PRINTER_STATUSES.find(status => code === status.code)?.name
		|| 'Онлайн'
	);
}


import { ButtonHTMLAttributes, FC } from 'react';
import block from 'utils/bem-css-module';

import style from './button-link.scss';

const b = block(style);

type Props = {
	size?: 'xs' | 's' | 'sm' | 'm' | 'l' | 'xl';
}

export const ButtonLink: FC<Props & ButtonHTMLAttributes<HTMLButtonElement>> = ({
	children,
	className,
	size,
	...rest
}) => {
	return (
		<button className={b({ size }).mix(className)} {...rest}>
			{children}
		</button>
	);
};

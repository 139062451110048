import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getScanner } from 'api/api';
import { findDocumentByOperationId } from 'utils/document';
import { post } from 'utils/fetch';

import { deleteScanDocument } from 'actions/documents';
import { apiPath } from 'constants/api-path';

import { ScanOperationStatus } from 'types/document';
import { State } from 'types/state';

export function useRepeatScanning() {
	const [loading, setLoading] = useState(false);
	const { active_scan_order } = useSelector(({ user, documents, points }: State) => {
		return {
			points,
			active_scan_order: user ? user.active_scan_order : null,
			document: documents && user?.active_scan_order ?
				findDocumentByOperationId(documents, user.active_scan_order.id) || null : null,
		};
	});
	const dispatch = useDispatch();

	const onRepeatScanning = async () => {
		setLoading(true);
		if (!active_scan_order?.operation_scanner) {
			setLoading(false);
			return 'Данная операция сканирования не может быть повторена, так как была удалена.';
		}
		const scanner = await getScanner(active_scan_order.operation_scanner);
		const response = await post(apiPath.SCANNERS.OPERATIONS, {
			operation_scanner: scanner.pk,
			color_option: scanner.scanner_options_color[0].pk,
			resolution_option: scanner.scanner_options_resolution[0].pk,
		});
		setLoading(false);
		if (response.ok) {
			if (active_scan_order.status === ScanOperationStatus.failConverting) {
				dispatch(deleteScanDocument(active_scan_order.id));
			}
		} else {
			return (await response.json()).detail;
		}
	};
    
	return { loading, onRepeatScanning };
}

import { forwardRef, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import style from './popup.scss';

const b = block(style);

export const Popup = forwardRef<HTMLUListElement, HTMLAttributes<HTMLUListElement>>(({
	className,
	children,
	...rest
}, ref) => {
	return (
		<ul className={b().mix(className)} ref={ref} {...rest}>
			{children}
		</ul>
	);
});

import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import style from './progress-bar.scss';

const b = block(style);

type Props = {
    percent: number;
}

export const ProgressBar: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	className,
	percent,
	...rest
}) => {
	return (
		<div className={b().mix(className)} {...rest}>
			<div className={b('bar')} style={{ width: `${Math.min(percent, 100)}%` }} />
		</div>
	);
};

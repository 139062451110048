import { ReactElement } from 'react';
import block from 'utils/bem-css-module';

import style from './error-icon.scss';


type ErrorIconProps = {
	className?: string;
	onClick?: () => void;
}
const b = block(style);

export function ErrorIcon({
	className = '',
	onClick,
}: ErrorIconProps): ReactElement {
	return(
		<div className={b().mix(className)}
			 onClick={onClick}
		/>
	);
}

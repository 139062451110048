import { apiPath } from 'constants/api-path';

import { Document } from 'types/document';

import { asyncXMLHttpRequest } from './fetch';

export async function uploadFile(file: File, options = { x: 1, y: 1 }, onprogress?: XMLHttpRequestUpload['onprogress']): Promise<Document | undefined> {
	const formData = new FormData();
	formData.append('n_up_option_x', options.x.toString());
	formData.append('n_up_option_y', options.y.toString());
	formData.append('content', file);
	formData.append('name', file.name);
	return (await asyncXMLHttpRequest(formData, apiPath.DOCUMENTS_UPLOAD, onprogress) as Document);
}

export function readFileAsync(file: File): Promise<string | ArrayBuffer | null>  {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();

		reader.onload = () => {
			resolve(reader.result);
		};

		reader.onerror = reject;
		reader.readAsDataURL(file);
	});
}

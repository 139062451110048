import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { cancelCurrentScanOperation } from 'utils/request';

import { deleteScanDocument } from 'actions/documents';
import { Button } from 'components/Button';
import { ModalButtons, ModalContent, ModalHeader, ModalPortal } from 'components/Modal';
import { useRepeatScanning } from 'hooks/use-repeat-scanning';

type Props = {
    onClose: () => void;
	operationId: number | null;
}

const DEFAULT_ERROR = 'Извините за неудобства.\n' +
	'Мы уже работаем над устранением ошибки. Пожалуйста, попробуйте еще раз позже.';

export const ScanErrorModal: FC<Props> = ({
	onClose,
	operationId,
}) => {
	const { loading, onRepeatScanning } = useRepeatScanning();
	const id = `scan-error-modal-${operationId}`;
	const [error, setError] = useState(DEFAULT_ERROR);
	const dispatch = useDispatch();
	const isOpen = Boolean(operationId);

	const onDelete = () => {
		if (!operationId) {
			return;
		}
		dispatch(deleteScanDocument(operationId));
		cancelCurrentScanOperation(operationId);
		onClose();
	};

	const afterCLose = () => {
		setError(DEFAULT_ERROR);
	};
	
	const onRepeat = async () => {
		const error = await onRepeatScanning();
		if (error) {
			setError(error);
		} else {
			onClose();
		}
	};
	
	return (
		<ModalPortal id={id} isOpen={isOpen} afterCLose={afterCLose}>
			<ModalHeader id={id} isOpen={isOpen}>
                Сканирование не&nbsp;удалось
			</ModalHeader>
			<ModalContent id={id} size='sm'>
				{error}
			</ModalContent>
			<ModalButtons>
				{
					!error && (
						<Button
							buttonType='default'
							onClick={onRepeat}
							loading={loading}
							size='sm'
							disabled={!isOpen}
						>
							Повторить
						</Button>
					)
				}
				<Button
					buttonType='danger'
					onClick={onDelete}
					loading={loading}
					size='sm'
					disabled={!isOpen}
				>
                    Удалить
				</Button>
			</ModalButtons>
		</ModalPortal>
	);
};

import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';
import { roundSizeInKb } from 'utils/document';

import { Paragraph } from 'components/Paragraph';
import { ProgressBar } from 'components/ProgressBar';

import style from './document-entity.scss';

const b = block(style);

type Props = {
    size: number;
    loadedSize: number;
}

export const DocumentProgress: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	className,
	size,
	loadedSize,
	...rest
}) => {
	const percent = Math.min((loadedSize / size) * 100, 99);
	const loadedSizeInKb = roundSizeInKb(loadedSize, 'down');
	return (
		<div className={b('description', { progress: true }).mix(className)} {...rest}>
			<Paragraph size='xs'>Загрузка {Math.floor(percent)}% — {loadedSizeInKb} / {roundSizeInKb(size, 'up')} Кб</Paragraph>
			<ProgressBar percent={percent} />
		</div>
	);
};

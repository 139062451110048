import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import style from './popup.scss';

const b = block(style);


export const PopupItem: FC<HTMLAttributes<HTMLLIElement>> = ({
	className,
	children,
	...rest
}) => {
	return (
		<li className={b('item').mix(className)} {...rest}>
			{children}
		</li>
	);
};

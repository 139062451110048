import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import { Heading } from 'components/Heading';
import { Paragraph } from 'components/Paragraph';

import style from './instruction.scss';

const b = block(style);


type Props = {
	orientation: 'row' | 'column';
	withPlaceSelection: boolean;
}

export const Instruction: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	className,
	orientation,
	withPlaceSelection,
	...rest
}) => {
	const breakSpace = orientation === 'column' ? ' ' : <br/>;
	return (
		<div className={b({ orientation }).mix(className)} {...rest}>
			<Heading level={2} size='l'>Как это работает?</Heading>
			<ul className={b('list')}>
				{withPlaceSelection && (
					<li className={b('card')}>
						<div className={b('image', { type: 'map' })} />
						<div>
							<Heading level={3} size='m' className={b('card-title')}>
								Выбери адрес
							</Heading>
							<Paragraph size='sm'>Автомат или{ breakSpace }пункт&nbsp;печати</Paragraph>
						</div>
					</li>
				)}
				<li className={b('card')}>
					<div className={b('image', { type: 'attach-doc' })} />
					<div>
						<Heading level={3} size='m' className={b('card-title')}>
							Загрузи{ breakSpace }документы
						</Heading>
						<Paragraph size='sm'>Размер до 3 Mб</Paragraph>
					</div>
				</li>
				<li className={b('card')}>
					<div className={b('image', { type: 'card' })} />
					<div>
						<Heading level={3} size='m' className={b('card-title')}>
							Выбери опции{ breakSpace }и оплати
						</Heading>
						<Paragraph size='sm'>Оплата онлайн</Paragraph>
					</div>
				</li>
				<li className={b('card')}>
					<div className={b('image', { type: 'flag' })} />
					<div>
						<Heading level={3} size='m' className={b('card-title')}>
							Забери заказ
						</Heading>
						<Paragraph size='sm'>В рабочие часы точки&nbsp;печати</Paragraph>
					</div>
				</li>
			</ul>
		</div>
	);
};

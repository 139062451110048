import { ChangeEvent, FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import { Heading } from 'components/Heading';
import { YANDEX_METRIC_ID } from 'constants/common';

import style from './file-drag-and-drop.scss';

const b = block(style);

const DEFAULT_HEADING = 'Загрузить документы';

type Props = {
    id: string;
    allowedMimeTypes: string;
	onFileUpload:  (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
	heading?: string;
	fileInputKey: string;
}

const onFileDragAndDropClick = () => {
	ym(YANDEX_METRIC_ID, 'reachGoal', 'document-upload-button-click'); // Нажатие на кнопку загрузки файла (или область drag-n-drop)
};

export const FileDragAndDrop: FC<Props & HTMLAttributes<HTMLElement>> = ({
	id,
	allowedMimeTypes,
	className,
	onFileUpload,
	fileInputKey,
	heading = DEFAULT_HEADING,
	children,
	...rest
}) => {
	
	return (
		<div className={b().mix(className)} {...rest}>
			<label htmlFor={id}>
				<Heading level={3} size='m'>
					{heading}
				</Heading>
				<div>
					{children}
				</div>
			</label>
			<input
				type='file'
				id={id}
				accept={allowedMimeTypes}
				onChange={onFileUpload}
				multiple={true}
				onClick={onFileDragAndDropClick}
				key={fileInputKey}
			/>
		</div>
	);
};

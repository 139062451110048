import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { subscribeToDocuments } from 'utils/sockets';

import { Document, ScanningDocument } from 'types/document';
import { State } from 'types/state';

export const useSubscribeToDocument = (callBack: (doc: Document | ScanningDocument) => void): void => {
	const { userId, centToken, centTimeToken } = useSelector(({ user }: State) => {
		return {
			userId: user?.id,
			centToken: user?.cent_token,
			centTimeToken: user?.cent_time_token,
		};
	});
	useEffect(() => {
		if (userId && centToken && centTimeToken) {
			subscribeToDocuments(callBack, userId, centToken, centTimeToken);
		}
	}, [userId, centToken, centTimeToken, callBack]);
};

import { FC } from 'react';
import { useDispatch } from 'react-redux';
import block from 'utils/bem-css-module';
import { getDocumentName } from 'utils/document';

import { deleteScanDocument } from 'actions/documents';
import { PopupItem, PopupItemLink } from 'components/Popup';

import { DocumentStatus, ScanningDocument, ScanOperationStatus } from 'types/document';
import { Document } from 'types/document';

import style from './document-entity.scss';
const b = block(style);

type Props = {
    doc: Document | ScanningDocument;
    setDeletedDoc: (pk: number) => void;
    showErrorInfo: () => void;
}

export const DocumentEntityPopupItems: FC<Props> = ({
	doc,
	setDeletedDoc,
	showErrorInfo,
}) => {

	const dispatch = useDispatch();

	const onDelete = () => {
		if ('operation_id' in doc) {
			dispatch(deleteScanDocument(doc.operation_id));
		} else {
			setDeletedDoc(doc.pk);
		}
	};

	if (doc.status == DocumentStatus.processed) {
		return (
			<>
				<PopupItemLink
					download={`${getDocumentName(doc.name)}.pdf`}
					href={doc.pdf_url || undefined}
					className={b('popup-download')}
				>
					Скачать
				</PopupItemLink>
				<PopupItem className={b('popup-delete')} onClick={onDelete}>Удалить</PopupItem>
			</>
		);
	}

	if ([DocumentStatus.error, ScanOperationStatus.failConverting].includes(doc.status)) {
		return (
			<>
				<PopupItem className={b('popup-info')} onClick={showErrorInfo}>Узнать причину</PopupItem>
				<PopupItem className={b('popup-delete')} onClick={onDelete}>Удалить</PopupItem>
			</>
		);
	}

	return null;
};

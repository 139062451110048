import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { filterOutUploadingDocuments, filterProcessedDocuments } from 'utils/document';
import { getSettingsPrintURL } from 'utils/urls';

import { DeleteDocumentModal } from 'components/DeleteDocumentModal';
import { DocumentEntity } from 'components/DocumentEntity';
import { DocumentUploadingEntity } from 'components/DocumentEntity/document-uploading-entity';
import { DocumentErrorModal } from 'components/DocumentErrorModal';
import { Paginate } from 'components/Paginate';
import { ScanErrorModal } from 'components/ScanErrorModal';
import { usePaginator } from 'hooks/use-paginator';
import { useShowDocuments } from 'hooks/use-show-documents';

import { Document, DocumentErrorInfo, ScanningDocument } from 'types/document';
import { State } from 'types/state';

import { DocumentsButtons } from './documents-buttons';
import { DocumentsLoader } from './documents-loader';


type Props = {
    className?: string;
    documentsPerPage?: number;
}

const DOCUMENTS_PER_PAGE = 7;

export const Documents: FC<Props> = ({
	className = '',
	documentsPerPage = DOCUMENTS_PER_PAGE,
}) => {

	const { pagination , selectPage } = usePaginator(documentsPerPage);
	const { documents } = useSelector(({ documents }: State) => ({ documents }));
	const [deletedDocuments, setDeletedDocuments] = useState<Set<number>>(new Set());
	const [selectedDocuments, setSelectedDocuments] = useState<Set<number>>();
	const [isErrorInfoModalOpen, setIsErrorInfoModalOpen] = useState(false);
	const [errorInfo, setErrorInfo] = useState<DocumentErrorInfo>();
	const [operationId, setOperationId] = useState<number | null>(null);
	const [isSelectedAllChecked, setIsSelectedAllChecked] = useState(false);
	const history = useHistory();
	const hasDocuments = useShowDocuments();

	const onClose = useCallback((): void => {
		setDeletedDocuments(new Set());
	}, []);

	const showErrorInfo = useCallback((document: ScanningDocument | Document) => () => {
		if ('error_info' in document && document.error_info) {
			setErrorInfo(document.error_info);
			setIsErrorInfoModalOpen(true);
			return;
		}
		setOperationId((document as ScanningDocument).operation_id);
	}, []);

	useEffect(() => {
		if (!isSelectedAllChecked) {
			return;
		}
		const loadedDocs = filterProcessedDocuments(documents!).map(({ pk }) => pk);
		if (loadedDocs.length === 0) {
			return;
		}
		setSelectedDocuments(new Set([...loadedDocs]));
	}, [isSelectedAllChecked, documents]);
	
	const onPrintButtonClick = () => {
		if (!selectedDocuments) {
			setSelectedDocuments(new Set());
			return;
		}
		redirectToPrint([...selectedDocuments!]);
	};

	const redirectToPrint = (pks: number[]) => {
		history.push(getSettingsPrintURL(pks));
	};

	const toggleSelectedDocument = (selectedPk: number) => () => {
		if (!selectedDocuments) {
			setSelectedDocuments(new Set([selectedPk]));
			return;
		}
		if (selectedDocuments.has(selectedPk)) {
			selectedDocuments.delete(selectedPk);
			setSelectedDocuments(new Set(selectedDocuments));
			setIsSelectedAllChecked(false);
			return;
		}
		setSelectedDocuments(new Set(selectedDocuments.add(selectedPk)));
	};

	const onSelectAllClick = (checked: boolean) => {
		setIsSelectedAllChecked(checked);
		if (checked) {
			setSelectedDocuments(new Set(filterProcessedDocuments(documents!).map(({ pk }) => pk)));
			return;
		}
		setSelectedDocuments(new Set([]));
	};
	
	const onDeleteButtonClick = () => {
		if (!documents) {
			return;
		}
		setDeletedDocuments(new Set(
			filterOutUploadingDocuments(documents).map(document => document.pk)),
		);
	};

	if (!documents) {
		if (hasDocuments) {
			return (
				<>
					<DocumentsButtons
						selectedDocumentPks={selectedDocuments}
						onPrintButtonClick={onPrintButtonClick}
						onDeleteButtonClick={onDeleteButtonClick}
						onSelectAllClick={onSelectAllClick}
						onCancel={() => setSelectedDocuments(undefined)}
						disabled={true}
					/>
					<DocumentsLoader/>
				</>
			);
		}
		return null;
	}

	return (
		<>
			{documents.length > 0 &&
				<>
					<DocumentsButtons
						selectedDocumentPks={selectedDocuments}
						onPrintButtonClick={onPrintButtonClick}
						onDeleteButtonClick={onDeleteButtonClick}
						onCancel={() => setSelectedDocuments(undefined)}
						onSelectAllClick={onSelectAllClick}
						disabled={filterProcessedDocuments(documents).length === 0}
					/>
					<section className={className}>
						{documents.slice(pagination.start, pagination.end).map((document) => {
							if (!('pk' in document)) {
								return (
									<DocumentUploadingEntity
										key={document.uploadingId}
										doc={document}
									/>
								);
							}
							return (
								<DocumentEntity
									key={document.pk}
									doc={document}
									setDeletedDoc={(pk) => setDeletedDocuments(new Set([pk]))}
									showErrorInfo={showErrorInfo(document)}
									setSelectedDocument={toggleSelectedDocument(document.pk)}
									isSelected={selectedDocuments ? selectedDocuments.has(document.pk) : undefined}
								/>
							);
						})}
					</section>
				</>
			}
			<Paginate length={documents.length} onPageChange={selectPage} itemsPerPage={documentsPerPage} />
			<DeleteDocumentModal
				close={onClose}
				documentPks={[...deletedDocuments]}
			/>
			<DocumentErrorModal
				isOpen={isErrorInfoModalOpen}
				afterCLose={() => setErrorInfo(undefined)}
				onClose={() => setIsErrorInfoModalOpen(false)}
				error={errorInfo}
				id='documents-error-modal'
			/>
			<ScanErrorModal
				operationId={operationId}
				onClose={() => setOperationId(null)}
			/>
		</>
	);
};

import { ComponentProps, FC } from 'react';
import block from 'utils/bem-css-module';

import { Link } from 'components/Link';
import { ModalContent, ModalHeader, ModalPortal } from 'components/Modal';

import { DocumentErrorInfo } from 'types/document';

import style from './document-error-modal.scss';
const b = block(style);

type Props = {
	onClose: () => void;
	error?: DocumentErrorInfo;
	isOpen: boolean;
}

const HEADER = {
	[DocumentErrorInfo.CONVERSION_ERROR_MAX_FILE_SIZE_REACHED]: 'Слишком долгая обработка файла',
	[DocumentErrorInfo.CONVERSION_ERROR_TIMEOUT]: 'Вес обработанного файла больше 3 Мб',
	[DocumentErrorInfo.UNKNOWN_ERROR]: 'Ошибка обработки',
};

export const DocumentErrorModal: FC<Props & ComponentProps<typeof ModalPortal>> = ({
	error,
	isOpen,
	onClose,
	id,
	...rest
}) => {
	return (
		<ModalPortal isOpen={isOpen} id={id} className={b('content')} withMobileStretch={true} {...rest}>
			<ModalHeader id={id} close={onClose} isOpen={isOpen}>
				{error && HEADER[error]}
			</ModalHeader>
			<ModalContent id={id} size='sm'>
				<article className={b('description')}>
					<p className={b('title')}>Для исправления ошибки попробуйте следующие шаги:</p>
					<ul>
						<li>
							<b>Преобразуйте файл в .pdf формат.</b><br/>
							Например, используя <Link url='https://smallpdf.com/ru/pdf-converter'>
							https://smallpdf.com/ru/pdf-converter</Link>.
						</li>
						<li>
							<b>Уменьшите размер .pdf файла.</b><br/>
							Например, используя <Link url='https://smallpdf.com/ru/compress-pdf'>
							https://smallpdf.com/ru/compress-pdf.</Link>
						</li>
						<li>
							<b>Разбейте файл на несколько документов меньшего размера.</b><br/>
							Например, используя <Link url='https://smallpdf.com/ru/split-pdf'>
							https://smallpdf.com/ru/split-pdf.</Link>
						</li>
					</ul>
				</article>
			</ModalContent>
		</ModalPortal>
	);
};

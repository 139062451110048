import { DocumentUploadingEntity } from 'components/DocumentEntity';

export const DocumentsLoader = () => {
	return (
		<>
			<DocumentUploadingEntity key='document-loading-1' />
			<DocumentUploadingEntity key='document-loading-2' />
			<DocumentUploadingEntity key='document-loading-3' />
		</>
	);
};

import { FC, HTMLAttributes, MouseEvent } from 'react';
import block from 'utils/bem-css-module';

import style from './ellipsis.scss';

const b = block(style);

type Props = {
    onClick?: (e: MouseEvent<HTMLDivElement>) => void;
    loading: boolean;
    disabled: boolean;
}

export const Ellipsis: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	className,
	onClick,
	loading,
	disabled,
	...rest
}) => {
	return (
		<div
			className={b({ loading, disabled }).mix(className)}
			onClick={onClick}
			{...rest}
		>
			<span className={b('item')} />
			<span className={b('item')} />
			<span className={b('item')} />
		</div>
	);
};

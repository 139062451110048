import { FC } from 'react';
import block from 'utils/bem-css-module';

import { DocumentLoading, ErrorIcon } from 'components/Icon';
import { ReactComponent as DocumentType } from 'components/Icon/svg/document-type.svg';
import { Loader } from 'components/Loader';

import { DocumentStatus, ScanOperationStatus, UploadingOperationStatus } from 'types/document';

import style from './document-entity.scss';
const b = block(style);

type Props = {
    status?: ScanOperationStatus | DocumentStatus | typeof UploadingOperationStatus;
}

export const DocumentEntityIcon: FC<Props> = ({
	status,
}) => {
	switch (status) {
		case DocumentStatus.processed:
			return (
				<div className={b('type')}>
					<DocumentType />
					<p className={b('type-name')}>pdf</p>
				</div>
			);
		case DocumentStatus.error:
		case ScanOperationStatus.failConverting:
			return <ErrorIcon className={b('type')} />;
		case ScanOperationStatus.scanWaiting:
			return <Loader className={b('type', { loader: true })} />;
		case ScanOperationStatus.scanning:
			return (
				<div className={b('type', { scan: true })}>
					<DocumentType />
				</div>
			);
		case ScanOperationStatus.successConverting:
			return <DocumentType className={b('type')} />;
		case undefined:
			return <div className={b('type', { 'doc-loader': true })} />;
	}

	return <DocumentLoading className={b('type')} />;
};

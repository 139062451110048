import { ReactElement } from 'react';
import block from 'utils/bem-css-module';

import { ReactComponent as DocumentType } from 'components/Icon/svg/document-type.svg';

import style from './document-scan-icon.scss';


const b = block(style);

type DocumentScanIconProps = {
	className?: string;
}

export const DocumentScanIcon = ({ className = '' }: DocumentScanIconProps): ReactElement => {
	return (
		<div className={b().mix(className)}>
			<DocumentType />
		</div>
	);
};

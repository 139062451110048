import { singletone } from 'components/panels/CentrifugeSingletone';
import { YANDEX_METRIC_ID } from 'constants/common';

import { Document, DocumentStatus, ScanningDocument } from 'types/document';

import { createScanDocument } from './document';

export const subscribeToDocuments = (addDocumentToDocuments: (doc: Document | ScanningDocument) => void, userId: string, centToken: string, centTimeToken: string): void => {
	const centrifuge = singletone(userId, centToken, centTimeToken);
	const channel = (`user_updates#${userId}`);

	centrifuge?.subscribe(channel, (msg: any) => {
		if (msg.data && msg.data.type) {
			const data = msg.data;
			if (data.type === 'document_update') {
				if (data.status === DocumentStatus.error) {
					ym(YANDEX_METRIC_ID,'reachGoal','document-upload-result-error');
				}
				if (data.status === DocumentStatus.processed) {
					ym(YANDEX_METRIC_ID,'reachGoal','document-upload-result-success');
				}
				addDocumentToDocuments(data);
			}
			if (data.type === 'scan_operations_update' && !data['is_finished']) {
				addDocumentToDocuments(createScanDocument(data));
			}
		}
	});
	centrifuge?.connect();
};

export function subscribeToUser(callBack: () => void, userId: string, centToken: string, centTimeToken: string) {
	const centrifuge = singletone(userId, centToken, centTimeToken);
	const channel = (`user_updates#${userId}`);
	centrifuge?.subscribe(channel, (msg: any) => {
		if (msg['data'] && msg['data']['type'] && msg['data']['type'] === 'profile_update') {
			callBack();
		}
	});
	centrifuge?.connect();
}

export function subscribeToPrintOperations(callBack: (id: number) => void, userId: string, centToken: string, centTimeToken: string) {
	const centrifuge = singletone(userId, centToken, centTimeToken);
	const channel = (`user_updates#${userId}`);
	centrifuge?.subscribe(channel, (msg: any) => {
		if (msg.data && msg.data.type === 'operations_update') {
			callBack(msg.data.id);
		}
	});
	centrifuge?.connect();
}

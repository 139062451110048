import { OpenHours, Status } from './common';

export type Point = {
	pk: number;
	place: number;
	is_whitelabel: boolean;
	short_point_name: string;
	short_address?: string; //fancy name specially generated from Yandex map
	full_address: string;
	additional_address_details: string;
	name: string;
	place_description: string;
	printers: Printer[];
	scanners: Scanner[];
	x_map_position: number;
	y_map_position: number;
	is_not_automatic_point: boolean;
	image_4x3: string | null;
	image_16x9: string | null;
	open_hours_dict: OpenHours | null;
}

export enum PrinterStatus {
	active = 10,
	inaccessible = 12,
	disabled = 11,
}

export type PrintForm = {
	copies: number | '';
	document: number;
	printer: number;
	color_option: OptionsColorType;
	duplex_option: OptionsDoublePageType;
}

export enum PrinterTag {
	bw = 'Ч/Б',
	scanner = 'Сканер',
	color = 'Цветной',

}

export type OptionsDoublePage = {
	pk: OptionsDoublePageType;
	name: string;
	cup_name: string;
}

export enum OptionsDoublePageType {
	singleSide = 1,
	twoSide = 4,
	twoSideTop = 3,
}

export enum OptionsColorType {
	bw = 'color_grayscale',
	colorful = 'color_cmyk',
}

export type OptionsColor = {
	pk: number;
	cup_name: OptionsColorType;
	name: string;
}

export type Printer = {
	name?: string;
	pk: number;
	status: PrinterStatus;
	tags?: PrinterTag;
	printer_options_double_page: OptionsDoublePage[];
	printer_options_color: OptionsColor[];
	paper_count: number;
	price: number;
	average_price_grayscale: number;
	average_price_color: number;
}

export type AdminDevices<T> = {
	data: T[];
	status?: Status;
	message?: string;
}

export type Scanner = {
	pk: number;
	name: string;
	created_at: string;
	status: PrinterStatus;
	status_name: string;
	place_description: string;
	description: string;
	tags?: PrinterTag | PrinterTag[];
	x_map_position: number;
	y_map_position: number;
	average_price: number;
	scanner_options_color: any;
	scanner_options_resolution: any;
}

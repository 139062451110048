import { FC } from 'react';
import ReactPaginate, { ReactPaginateProps } from 'react-paginate';

import './paginate.global.scss';

type Props = {
    length: number;
    itemsPerPage: number;
    onPageChange: (selectedItem: { selected: number }) => void;
}
export const Paginate: FC<Props & Partial<ReactPaginateProps>> = ({
	length,
	itemsPerPage,
	onPageChange,
	...rest
}) => {
	if (length <= itemsPerPage) {
		return null;
	}
	return (
		<ReactPaginate 
			previousLabel={<div className='pagination_back'>Назад</div>}
			nextLabel={<div className='pagination_next'>Вперед</div>}
			breakLabel={<a href=''>...</a>}
			breakClassName='break-me'
			pageCount={(Math.max(1, Math.ceil(length / itemsPerPage)))}
			marginPagesDisplayed={0}
			pageRangeDisplayed={2}
			onPageChange={onPageChange}
			containerClassName='pagination'
			activeClassName='active'
			{...rest}
		/>
	);
};

import { AnchorHTMLAttributes, FC } from 'react';
import block from 'utils/bem-css-module';

import style from './popup.scss';

const b = block(style);


export const PopupItemLink: FC<AnchorHTMLAttributes<HTMLElement>> = ({
	className,
	children,
	...rest
}) => {
	return (
		<li className={b('item', { type: 'link' }).mix(className)}>
			<a
				target='_blank'
				rel='noreferrer'
				{...rest}
			>
				{children}
			</a>
		</li>
	);
};

import { memo, ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import block from 'utils/bem-css-module';
import { cancelCurrentScanOperation } from 'utils/request';

import { DocumentLoading, DocumentScanIcon } from 'components/Icon';
import { ModalContent, ModalHeader, ModalPortal } from 'components/Modal';
import { ScanErrorModal } from 'components/ScanErrorModal';
import { SuccessScanModal } from 'components/SuccessScanModal';

import { DocumentStatus, ScanOperationStatus } from 'types/document';
import { State } from 'types/state';

import { CurrentOperations } from './current-operations';

import style from './current-operations-modal.scss';

const b = block(style);

export const CurrentOperationsModal = memo((): ReactElement | null => {
	const [isOpen, setIsOpen] = useState(true);
	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes('/scan')) {
			setIsOpen(true);
		}
	}, [location]);

	const { active_scan_order } = useSelector(({ user }: State) => {
		return {
			active_scan_order: user ? user.active_scan_order : null,
		};
	});
	
	if (!active_scan_order) {
		return <div/>;
	}

	const onClose = (): void => {
		setIsOpen(false);
		cancelCurrentScanOperation(active_scan_order.id);
	};

	if ([
		ScanOperationStatus.scanning,
		ScanOperationStatus.scanWaiting,
	].includes(active_scan_order.status))
		return (
			<>
				<CurrentOperations
                 	operations={[active_scan_order]}
                 	onClick={() => setIsOpen(true)}
				/>
				<ModalPortal
					id='current-scan-modal'
					isOpen={isOpen}
                 	className={b({ type: 'process' })}
				>
					<ModalHeader id='current-scan-modal' close={() => setIsOpen(false)} isOpen={isOpen}>
						Идет сканирование:<br/>страница №{active_scan_order.page_number}
					</ModalHeader>
					<ModalContent size='s' id='current-scan-modal'>
						<DocumentScanIcon className={b('icon')} />
					</ModalContent>
				</ModalPortal>
			</>
		);

	if ([
		ScanOperationStatus.converting,
		ScanOperationStatus.convertWaiting,
	].includes(active_scan_order.status)) {
		return (
			<>
				<CurrentOperations
                	operations={[active_scan_order]}
                	onClick={() => setIsOpen(true)}
				/>
				<ModalPortal
                	isOpen={isOpen}
                	className={b({ type: 'process' })}
					id='pick-up-document-modal'
				>
					<ModalHeader id='pick-up-document-modal' close={() => setIsOpen(false)} isOpen={isOpen}>
						Заберите документ из сканера
					</ModalHeader>
					<ModalContent size='s' id='pick-up-document-modal'>
						<DocumentLoading className={b('icon')}/>
					</ModalContent>
				</ModalPortal>
			</>
		);
	}

	if ([ScanOperationStatus.failConverting, DocumentStatus.error].includes(active_scan_order.status)) {
		return (
			<>
				<CurrentOperations
                	operations={[active_scan_order]}
                	onClick={() => setIsOpen(true)}
				/>
				<ScanErrorModal operationId={active_scan_order.id} onClose={onClose} />
			</>
		);
	}

	if ([ScanOperationStatus.successConverting].includes(active_scan_order.status)) {
		return (
			<>
				<CurrentOperations
                	operations={[active_scan_order]}
                	onClick={() => setIsOpen(true)}
				/>
				<SuccessScanModal onClose={onClose} />
			</>
		);
	}
	return <div />;
});

import { ComponentProps, FC, HTMLAttributes, MouseEvent,useCallback, useRef, useState } from 'react';
import { scrollToElement } from 'utils/element';

import { Popup } from 'components/Popup';
import { useClickOutside } from 'hooks/use-click-outside';

import { Ellipsis } from './ellipsis';


type Props = {
	popupClassname?: string;
} & ComponentProps<typeof Ellipsis>

export const EllipsisPopup: FC<Props & HTMLAttributes<HTMLDivElement>> = ({
	className,
	loading,
	disabled,
	onClick,
	children,
	popupClassname,
	...rest
}) => {
	const ref = useRef<HTMLUListElement>(null);

	const [showMenu, setShowMenu] = useState(false);
	const closeMenu = useCallback(() => setShowMenu(false), []);
	useClickOutside(ref, closeMenu);
    
	const toggleMenu = useCallback((e: MouseEvent<HTMLDivElement>) => {
		setShowMenu(show => !show);
		onClick?.(e);
		setTimeout(() => {
			if (ref.current) {
				scrollToElement(ref.current!);
			}
		}, 0);
	}, []);
    
	return (
		<>
			<Ellipsis
				onClick={toggleMenu}
				loading={loading}
				disabled={showMenu || disabled}
				className={className}
				{...rest}
			/>
			{showMenu && (
				<Popup ref={ref} className={popupClassname}>
					{children}
				</Popup>
			)}
		</>
	);
};

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentPoint } from 'actions/print';

import { Point } from 'types/point';
import { State } from 'types/state';

export const useCurrentPoint = (pointPk?: number, onError?: (e: Error) => void): Point | undefined => {
	const dispatch = useDispatch();
	useEffect(() => {
		if (pointPk) {
			dispatch(setCurrentPoint(pointPk, onError));
		}
	}, [pointPk]);
	
	return useSelector((state: State) => state.print.currentPoint);
};

import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';
import { getColorfulPrinter } from 'utils/point';
import { getBWPrintingPrice, getColorfulPrintingPrice } from 'utils/price';

import { Heading } from 'components/Heading';
import { Link } from 'components/Link';
import { Paragraph } from 'components/Paragraph';

import { Point } from 'types/point';

import style from './default-point.scss';

const b = block(style);


type Props = {
    point?: Point;
	withPrice: boolean;
	withLink: boolean;
}

export const DefaultPoint: FC<Props & HTMLAttributes<HTMLElement>> = ({
	point,
	className,
	withPrice,
	withLink,
	...rest
}) => {
	if (!point) {
		return (
			<section className={b().mix(className)} {...rest}>
				<Heading level={3} size='sm' className={b('description')}>
					Точные цены и доступные услуги зависят от адреса отделения.
				</Heading>
				{withLink && (
					<Link className={b('link')} url='/printers' size='sm' target='_self'>
						Найти ближайший пункт печати
					</Link>
				)}
			</section>
		);
	}
	return (
		<section className={b().mix(className)} {...rest}>
			<div className={b('name-address')}>
				<Paragraph size='sm' className={b('name')}>{point.short_point_name}:</Paragraph>
				<Paragraph size='sm' className={b('address')}>{point.short_address}</Paragraph>
			</div>
			{withPrice && (
				<Paragraph size='sm' className={b('price')}>
					Ч/Б печать А4: {getBWPrintingPrice(point, false)}
				</Paragraph>
			)}
			{withPrice && getColorfulPrinter(point, false) && (
				<Paragraph size='sm' className={b('price')}>
					Цветная печать А4: {getColorfulPrintingPrice(point, false)}
				</Paragraph>
			)}
			{withLink && (
				<Link className={b('link')} url='/printers' size='sm' target='_self'>
					Выбрать другой адрес
				</Link>
			)}
		</section>
	);
};

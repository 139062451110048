export const ROOT_ID = 'app';

type MoveAtProps = {
	e: MouseEvent | TouchEvent;
	element: HTMLElement;
	shiftX?: number;
	shiftY?: number;
}
// передвинуть element под координаты курсора
// и сдвинуть на половину ширины/высоты для центрирования
export function moveAt({ e, element, shiftX = 0, shiftY = 0 }: MoveAtProps): void {
	if (!element) {
		return;
	}
	if (e instanceof MouseEvent) {
		element.style.left = e.clientX - shiftX + 'px';
		element.style.top = e.clientY - shiftY + 'px';
	}
	if (e instanceof TouchEvent) {
		element.style.left = e.targetTouches[0].clientX - shiftX + 'px';
		element.style.top = e.targetTouches[0].clientY - shiftY + 'px';
	}
}

export function getRootElement() {
	return document.getElementById(ROOT_ID)!;
}

export function scrollToTop() {
	document.querySelector('html')!.scrollTo(0,0);
}

export function isElementInView(element: HTMLElement) {
	const rect = element.getBoundingClientRect();
	const root = getRootElement();
	return (
		rect.top >= 0 &&
		rect.left >= 0 &&
		rect.bottom <= root.clientHeight &&
		rect.right <= root.clientWidth
	);
}

export function scrollToElement(element: HTMLElement) {
	if (!isElementInView(element)) {
		element.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'nearest' });
	}
}

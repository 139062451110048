import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import { Paragraph } from 'components/Paragraph';

import style from './checkbox.scss';

type Props = {
    onClick: () => void;
    checked: boolean;
	id: string;
	size: 'xs' | 's' | 'sm' | 'm' | 'l' | 'xl';
}

const b = block(style);

export const Checkbox: FC<Props & HTMLAttributes<HTMLElement>> = ({
	onClick,
	className,
	checked,
	children,
	id,
	size,
	...rest
}) => {
	return(
		<div className={b().mix(className)} tabIndex={0} {...rest}>
			<input
				id={id}
				type='checkbox'
				className={b('input')}
				checked={checked}
				onClick={onClick}
				readOnly
			/>
			<label
				htmlFor={id}
				className={b('label')}
			/>
			{children && <Paragraph size={size} className={b('title')}>{children}</Paragraph>}
		</div>
	);
};

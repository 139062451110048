import { ChangeEvent, FC, HTMLAttributes, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import block from 'utils/bem-css-module';
import { getIsMobile } from 'utils/common';

import { addDocumentToDocuments } from 'actions/documents';
import { FileButton } from 'components/FileButton';
import { FileDragAndDrop } from 'components/FileDragAndDrop';
import { AgreementBlock } from 'components/Login';
import { Paragraph } from 'components/Paragraph';
import { useScreenSize } from 'hooks/use-screen-size';
import { useShowDocuments } from 'hooks/use-show-documents';
import { useSubscribeToDocument } from 'hooks/use-subscribe-to-document';
import { useUploadFile } from 'hooks/use-upload-file';

import { BombSettings } from 'types/common';

import { BombService } from './bomb-service';
import { Documents } from './documents';

import style from './documents.scss';

const b = block(style);


type Props = {
    documentsPerPage: number;
}

export const DocumentsBlock: FC<Props & HTMLAttributes<HTMLElement>> = ({
	documentsPerPage,
	className,
	...rest
}) => {
	const hasDocuments = useShowDocuments();

	const dispatch = useDispatch();
	const subscribeToDocumentCallback = useCallback((documents) => dispatch(addDocumentToDocuments(documents)), []);
	useSubscribeToDocument(subscribeToDocumentCallback);
	const { onFileUpload, key } = useUploadFile();
	const { width } = useScreenSize();
	const isMobile = getIsMobile(width);
	const showDocumentsAdditionalInfo = hasDocuments || !isMobile;

	const onPrint = (settings: BombSettings) => async (e: ChangeEvent<HTMLInputElement>) => onFileUpload(settings)(e);

	return (
		<section className={b().mix(className)} {...rest}>
			{isMobile && (
				<FileButton
					onChange={onPrint({ x: 1, y: 1 })}
					multiple={true}
					size='m'
					className={b('file-button')}
					buttonType='success'
					ymEventName='document-upload-button-click'
					id={`mobile-upload-button-${key}`}
				>
					Загрузить документы
				</FileButton>
			)}
			{showDocumentsAdditionalInfo && <h2 className={b('title')}>Мои Документы</h2>}
			{showDocumentsAdditionalInfo && (
				<Paragraph size='s'>* Документы удалятся через 24 часа после загрузки</Paragraph>
			)}
			{!isMobile && (
				<FileDragAndDrop
					id='desktop-upload-button'
					allowedMimeTypes='*'
					className={b('drag-and-drop')}
					onFileUpload={onPrint({ x: 1, y: 1 })}
					fileInputKey={key}
				>
					поддерживаемые форматы<br/>
					pdf, doxc, jpg, jpeg, doc, odt, xls, xlsx, rtf, txt, pptx, bpm, tif, gif, ods, html, odg, jfif
					<BombService
						onPrint={onPrint}
						className={b('bomb-button')}
						fileInputKey={key + 1}
					/>
				</FileDragAndDrop>
			)}
			{!hasDocuments && <AgreementBlock />}
			<Documents // must always be displayed in this component for document deletion to work correctly
				documentsPerPage={documentsPerPage}
				className={b('container')}
			/>
		</section>
	);
};

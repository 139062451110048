import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { createScanDocument } from 'utils/document';
import { get, remove, setURLParams } from 'utils/fetch';
import { readFileAsync, uploadFile } from 'utils/file';

import { TYPE } from 'constants/action-types';
import { apiPath } from 'constants/api-path';
import { YANDEX_METRIC_ID } from 'constants/common';

import { AnyDocument, Document, UploadingOperationStatus } from 'types/document';
import { State } from 'types/state';


type DocumentsThunkAction<R> = ThunkAction<R, State, void, Action>;

export function addDocumentToDocuments(data: AnyDocument) {
	return {
		type: TYPE.DOCUMENTS.ADD,
		data,
	};
}

export function receiveDocuments (data: { result: Document[] }) {
	return {
		type: TYPE.DOCUMENTS.RECEIVE,
		data,
	};
}

export function deleteScanDocument (operation_id: number) {
	return {
		type: TYPE.DOCUMENTS.DELETE_SCAN_DOCUMENT,
		operation_id,
	};
}

/**
 * Uses only for initial documents request
 * @param params
 */
export function getDocuments(params?: {[param: string]: string}): DocumentsThunkAction<void> {
	return async (dispatch, getState)  => {
		const documents = getState().documents;
		if (documents) {
			return;
		}
		const newDocuments = await get<{result: Document[]}>(setURLParams(apiPath.DOCUMENTS, params));
		dispatch(receiveDocuments(newDocuments));
		const user = getState().user;
		if (user && user.active_scan_order) {
			dispatch(addDocumentToDocuments(createScanDocument(user.active_scan_order)));
		}
	};
}

export function deleteDocument(documentPk: number): DocumentsThunkAction<void> {
	return async (dispatch) => {
		const response = await remove(`${apiPath.DOCUMENTS}${documentPk}/`);
		if (!response.ok) {
			throw Error('Can not delete document');
		}
		const newDocuments = await get<{result: Document[]}>(setURLParams(apiPath.DOCUMENTS));
		dispatch(receiveDocuments(newDocuments));
	};
}


export function uploadDocument(files: File[], bombSettings = { x: 1, y: 1 })
	: DocumentsThunkAction<Promise<Promise<Document | undefined>[]>> {
	return async (dispatch) => {
		function onProgress(fileName: string, uploadingId: string) {
			return (e: ProgressEvent) => {
				dispatch(addDocumentToDocuments({
					status: UploadingOperationStatus,
					name: fileName,
					size: e.total,
					uploadedSize: e.loaded,
					uploadingId,
				}));
			};
		}
		const promises: Promise<Document | undefined>[] = [];
		for (const file of files) {
			const readFile = await readFileAsync(file);
			if (!readFile) {
				// @TODO add error message for user
				return [];
			}
			const uploadingId = Date.now() + file.name;
			dispatch(addDocumentToDocuments({
				status: UploadingOperationStatus,
				name: file.name,
				size: file.size,
				uploadedSize: 0,
				uploadingId,
			}));
			ym(YANDEX_METRIC_ID,'reachGoal','document-upload-started'); // Начало отправки файла на сервер (до ответа сервера)
			promises.push(uploadFile(file, bombSettings, onProgress(file.name, uploadingId))
				.then((document) => {
					ym(YANDEX_METRIC_ID, 'reachGoal', 'document-upload-finished'); // Сервер принял файл (вернул ответ с его ID)
					return document;
				})
				.catch((error) => {
					console.error('Error uploading file:', error);
					return undefined;
				}),
			);
		}
		return promises;
	};
}

import { memo, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { YMaps } from '@pbe/react-yandex-maps';

import { CurrentOperationsModal } from 'components/CurrentOperations';
import { EmailChangedPopup } from 'components/EmailChangedPopup';
import { RestorePopup } from 'components/RestorePopup';
import { useGetRootData } from 'hooks/use-get-root-data';
import { useSubscribeToUser } from 'hooks/use-subscribe-to-user';
import { AdminConversationsPage } from 'pages/AdminConversations';
import { FirstPage } from 'pages/FirstPage';
import { MapWithQrLazy } from 'pages/Map';
import { PrintSettingsPage } from 'pages/PrintSettings';
import { ScanOptionsPage } from 'pages/Scan';

import { Show } from 'types/map';

export const MainRoot = () => {
	useGetRootData();
	useSubscribeToUser();
	return (
		<YMaps
			query={{
				load: 'package.full',
				apikey: '1db021c4-8c56-4862-ac93-c143ba6dfbbe',
				lang: 'ru_RU',
				mode: 'release',
			}}
		>
			<CurrentOperationsModal />
			<Suspense fallback={<div/>}>
				<Switch>
					{window.isStaff && <Route path='/administration' component={AdminConversationsPage} />}
					<Route path='/print/settings' component={PrintSettingsPage} />
					<Route path='/print/map' component={() =>
						<MapWithQrLazy show={Show.printers} />
					}/>
					<Route path	='/scan/map' component={() =>
						<MapWithQrLazy show={Show.scanners} />
					}/>
					<Route path='/scan/' component={ScanOptionsPage} />
					<Route path='/copy/' component={ScanOptionsPage} />
					<Route path='/lost_password/' component={memo(() =>
						<>
							<RestorePopup isOpen={true} />
							<FirstPage />
						</>,
					)} />
					<Route path='/email_changed/' component={memo(() =>
						<>
							<EmailChangedPopup isOpen={true} />
							<FirstPage />
						</>,
					)} />
					<Route path='/' component={FirstPage} />
				</Switch>
			</Suspense>
		</YMaps>
	);
};

import { ChangeEvent, FC, HTMLAttributes, useState } from 'react';

import { Button } from 'components/Button';
import { SeveralPageModal } from 'components/SeveralPageModal';
import { YANDEX_METRIC_ID } from 'constants/common';

import { BombSettings } from 'types/common';

type Props = {
	onPrint: (settings: BombSettings) => (e: ChangeEvent) => Promise<void>;
	fileInputKey: string;
}

export const BombService: FC<Props & HTMLAttributes<HTMLButtonElement>> = ({
	onPrint,
	className,
	fileInputKey,
	...rest
}) => {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [settings, setSettings] = useState({ x: 1, y: 1 });

	const onBombModalClose = () => {
		setIsModalOpen(false);
		setSettings({ x: 1, y: 1 });
	};

	const onConfirm = (e: ChangeEvent) => {
		setIsModalOpen(false);
		onPrint(settings)(e);
		setSettings({ x: 1, y: 1 });
	};

	const onBombServiceClick = () => {
		ym(YANDEX_METRIC_ID,'reachGoal','on-bomb-service-click');
		setIsModalOpen(true);
	};

	return (
		<>
			<SeveralPageModal
				isOpen={isModalOpen}
				onClose={onBombModalClose}
				set={setSettings}
				options={settings}
				onConfirm={onConfirm}
				fileInputKey={fileInputKey}
			/>
			<Button
				buttonType='default'
				size='xs'
				onClick={onBombServiceClick}
				className={className}
				{...rest}
			>
				Несколько страниц на листе
			</Button>
		</>
	);
};

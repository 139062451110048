import { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import block from 'utils/bem-css-module';

import { deleteDocument } from 'actions/documents';
import { Button } from 'components/Button';
import { ModalButtons, ModalContent, ModalHeader, ModalPortal } from 'components/Modal';

import style from './delete-document-modal.scss';

type Props = {
	close: () => void;
	documentPks?: number[];
}

const BODY_TEXT = (count: number) => ({
	default: `После удаления, восстановить документ${count > 1 ? 'ы' : ''} будет невозможно.`,
	loading: 'Подождите пожалуйста. Это может занять некоторое время.',
	error: `Не удалось удалить документ${count > 1 ? 'ы' : ''}. Проверьте подключение к интернету.`,
	success: `Удаление документ${count > 1 ? 'ов' : ''} завершено!\nТеперь можно закрыть это окно.`,
});

const TITLE_TEXT = (count: number) => ({
	default: `Удалить документ${count > 1 ? 'ы' : ''}?`,
	loading: `Удаляем документ${count > 1 ? 'ы' : ''}...`,
	error: `Удалить документ${count > 1 ? 'ы' : ''}?`,
	success: `Документ${count > 1 ? 'ы' : ''} удален${count > 1 ? 'ы' : ''}.`,
});

const b = block(style);


export const DeleteDocumentModal: FC<Props> = ({
	close,
	documentPks = [],
}) => {
	const dispatch = useDispatch();
	const [state, setState] = useState<'error' | 'loading' | 'default' | 'success'>('default');
	const onDelete = async () => {
		if (documentPks.length === 0) {
			setState('error');
			return;
		}
		try {
			setState('loading');
			await Promise.all(documentPks.map(async (pk) => {
				return dispatch(deleteDocument(pk));
			}));
			setState('success');
		} catch (e) {
			setState('error');
		}
	};
	const onClose = () => {
		close();
	};

	const isOpen = documentPks.length > 0;

	return(
		<ModalPortal
			id='delete-doc-modal'
			isOpen={isOpen}
			afterCLose={() => setState('default')}
		>
			<ModalHeader id='delete-doc-modal' close={state !== 'loading' ? onClose : undefined} isOpen={isOpen}>
				{TITLE_TEXT(documentPks.length)[state]}
			</ModalHeader>
			<ModalContent className={b('body')} size='sm' id='delete-doc-modal'>
				{BODY_TEXT(documentPks.length)[state]}
			</ModalContent>
			<ModalButtons>
				{state === 'success' ?
					<Button
						buttonType='success'
						onClick={onClose}
						size='sm'
						disabled={!isOpen}
					>
						Хорошо
					</Button>
					:
					<>
						<Button
							buttonType='default'
							onClick={onClose}
							loading={state === 'loading'}
							size='sm'
							disabled={!isOpen}
						>
							Отменить
						</Button>
						<Button
							buttonType='danger'
							onClick={onDelete}
							loading={state === 'loading'}
							size='sm'
							disabled={!isOpen}
						>
							{state === 'error' ? 'Повторить' : 'Удалить'}
						</Button>
					</>
				}
			</ModalButtons>
		</ModalPortal>
	);
};

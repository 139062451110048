import { FC } from 'react';
import block from 'utils/bem-css-module';
import { getDocumentDescription, getDocumentName } from 'utils/document';

import { Checkbox } from 'components/Checkbox';
import { ProgressBar } from 'components/ProgressBar';

import { Document, DocumentStatus, ScanningDocument, ScanOperationStatus } from 'types/document';

import { DocumentEntityActions } from './document-entity-actions';
import { DocumentEntityIcon } from './document-entity-icon';

import style from './document-entity.scss';

const b = block(style);


type Props = {
    doc: Document | ScanningDocument;
	setDeletedDoc: (pk: number) => void;
	showErrorInfo: () => void;
	setSelectedDocument: () => void;
	isSelected?: boolean;
}


export const DocumentEntity: FC<Props> = ({
	doc,
	setDeletedDoc,
	showErrorInfo,
	isSelected,
	setSelectedDocument,
}) => {
	const progress = [DocumentStatus.pending, DocumentStatus.waiting].includes(doc.status as DocumentStatus & ScanOperationStatus);
	return (
		<div className={b()}>
			<DocumentEntityIcon status={doc.status} />
			<article className={b('info', { checkbox: isSelected !== undefined })}>
				<p className={b('title')}>{getDocumentName(doc.name)}</p>
				<div className={b('description', { progress })}>
					{getDocumentDescription(doc)}
					{progress && <ProgressBar percent={100} />}
				</div>
			</article>
			{isSelected !== undefined ?
				doc.status === DocumentStatus.processed ?
					<Checkbox
						onClick={setSelectedDocument}
						checked={isSelected}
						id={`document-entity-${doc.pk}-checkbox`}
						size='s'
					/> 
					:
					<DocumentEntityActions
						doc={doc}
						setDeletedDoc={setDeletedDoc}
						showErrorInfo={showErrorInfo}
					/>
				:
				<DocumentEntityActions
					doc={doc}
					setDeletedDoc={setDeletedDoc}
					showErrorInfo={showErrorInfo}
				/>
			}
		</div>
	);
};

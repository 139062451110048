import { PrintOptionsForPrinter } from 'types/print';

export function savePrintOptions(documentPk: number, options: PrintOptionsForPrinter) {
	localStorage.setItem(documentPk.toString(), JSON.stringify(options));
}

export function getPrintOptionsFromLocalStorage(documentPk: number): PrintOptionsForPrinter | null {
	const options = localStorage.getItem(documentPk.toString());
	if (!options) {
		return null;
	}
	return JSON.parse(options);
}

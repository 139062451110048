import { FC, HTMLAttributes } from 'react';
import block from 'utils/bem-css-module';

import style from './text-loader.scss';

const b = block(style);

export const TextLoader: FC<HTMLAttributes<HTMLDivElement>> = ({
	className,
	...rest
}) => {
	return (
		<>
			<div className={b().mix(className)} {...rest} />
			<div className={b().mix(className)} {...rest} />
			<div className={b().mix(className)} {...rest} />
		</>
	);
};

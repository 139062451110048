export function subscribe(userId: string, centToken: string, centTimeToken: string): typeof Centrifuge {
	const info: any = document.getElementById('cent-data');
	if (!Centrifuge) {
    	return;
	}

	const centrifuge = new Centrifuge({
		url: info.dataset.centUrl,
		user: userId,
		info: '',
		token: centToken,
		timestamp: centTimeToken,
		// debug : true,
	});
	centrifuge.connect();
	return centrifuge;
}

export const singletone = (function () {
	let instance: typeof Centrifuge;
	let id: string;
	let token: string;
	let time: string;
	return function getSingletone (userId: string, centToken: string, centTimeToken: string) {
		if (instance && userId === id && centToken === token && centTimeToken === time) {
			return instance;
		}
		instance = subscribe(userId, centToken, centTimeToken);
		id = userId;
		token = centToken;
		time = centTimeToken;
		return instance;
	};
}());

import { getPoint, getPrintCost } from 'api/api';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { asyncRetry } from 'utils/fetch';
import { getAvailablePrinters } from 'utils/point';
import { getColorOptionsChangeError, getPaperCountError, getPrintOptionsForDocument } from 'utils/print-options';

import { TYPE } from 'constants/action-types';
import { DEFAULT_PRINT_OPTIONS_FOR_PRINTER } from 'constants/default';
import { PRINT_ERRORS } from 'constants/text';

import { Document } from 'types/document';
import { Point } from 'types/point';
import {
	PrintOptionsForDocumentError,
	PrintOptionsForDocuments,
	PrintOptionsForPrinter,
	PrintOptionsType,
} from 'types/print';
import { State } from 'types/state';

export const changeOptionsPrint = (options: Partial<PrintOptionsType>) => {
	return {
		type: TYPE.PRINT.CHANGE_OPTIONS,
		options,
	};
};

export function setCurrentPoint(pk: number, onError?: (e: Error) => void): ThunkAction<void, State, never, Action> {
	return async (dispatch) => {
		try {
			const currentPoint: Point = await getPoint(pk);
			dispatch(changeOptionsPrint({ currentPoint }));
		} catch (e) {
			onError?.(e);
		}
	};
}

export function changePrintOptionsPrinterPk(printerPk: number, documentPk: number) {
	return async (dispatch, getState) => {
		const options: PrintOptionsForDocuments = { ...getState().print.options };
		const currentPoint = getState().print.currentPoint;
		if (!(documentPk in options) || !currentPoint) {
			// impossible state
			return undefined;
		}
		options[documentPk] = {
			...options[documentPk],
			printerPk,
			price: null,
		};
		dispatch(changeOptionsPrint({ options }));
		try {
			const price = await getPrintCost({
				...options[documentPk].options,
				document: options[documentPk].document.pk,
				printer: printerPk,
			});
			options[documentPk] = {
				...options[documentPk],
				price,
				printerPk,
			};
			dispatch(changeOptionsPrint({ options }));
			return undefined;
		} catch {
			return PRINT_ERRORS.COST_ERROR;
		}
	};
}

export function changeOptionsForDocument(pk: number, optionsForPrinter: PrintOptionsForPrinter): ThunkAction<Promise<string | undefined>, State, never, Action> {
	return async (dispatch, getState) => {
		const options: PrintOptionsForDocuments = { ...getState().print.options };
		const currentPoint = getState().print.currentPoint;
		if (!(pk in options) || !currentPoint) {
			// impossible state
			return undefined;
		}
		const document = options[pk].document;
		const previousOptions = { ... options[pk] };
		const printer = getAvailablePrinters(currentPoint.printers, document.pages_count, optionsForPrinter)[0];
		options[pk] = {
			options: optionsForPrinter,
			document,
			price: null,
			printerPk: printer ? printer.pk : null,
		} as PrintOptionsForDocumentError;
		dispatch(changeOptionsPrint({ options }));
		if (!printer) {
			if (previousOptions.options.color_option !== optionsForPrinter.color_option) {
				return getColorOptionsChangeError(document.pages_count, optionsForPrinter, currentPoint.printers);
			}
			return getPaperCountError(document.pages_count, optionsForPrinter, currentPoint.printers);
		}
		try {
			const price = await getPrintCost({
				...optionsForPrinter,
				printer: printer.pk,
				document: document.pk,
			});
			options[pk] = {
				...options[pk],
				price,
				printerPk: printer.pk,
			};
			dispatch(changeOptionsPrint({ options }));
			return undefined;
		} catch {
			return PRINT_ERRORS.COST_ERROR;
		}
	};
}

export function initOptionsForDocuments(documents: Document[]): ThunkAction<void, State, never, Action> {
	return async (dispatch, getState) => {
		const currentPoint = getState().print.currentPoint;
		if (!currentPoint || documents.length === 0) {
			return;
		}
		const options: PrintOptionsForDocuments = {};
		await Promise.all(documents.map(async document => {
			let optionsForPrinter = getPrintOptionsForDocument(document.pk);
			// единственное что мы знаем что currentPoint имеет активные принтеры
			let printer = getAvailablePrinters(currentPoint.printers, document.pages_count, optionsForPrinter)[0];
			// на случай если сохраненнные в localStorage опции невозможно удовлетворить
			if (!printer) {
				optionsForPrinter = DEFAULT_PRINT_OPTIONS_FOR_PRINTER;
				printer = getAvailablePrinters(currentPoint.printers, document.pages_count, optionsForPrinter)[0];
			}
			const printerPk = printer ? printer.pk : null;
			// если принтер printerPk несуществует показываем ошибку в опциях
			options[document.pk] = {
				options: optionsForPrinter,
				document,
				price: null,
				printerPk,
			};
			if (printerPk) {
				const price = await asyncRetry(getPrintCost, 3)({
					...optionsForPrinter,
					printer: printerPk,
					document: document.pk,
				});
				options[document.pk] = {
					options: optionsForPrinter,
					document,
					price,
					printerPk,
				};
			}
		}));
		dispatch(changeOptionsPrint({ options, selectedDocument: documents[0].pk }));
	};
}

import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import block from 'utils/bem-css-module';
import { findDocumentByOperationId } from 'utils/document';
import { getSettingsPrintURL } from 'utils/urls';

import { Alert } from 'components/Alert';
import { ButtonLink } from 'components/ButtonLink';
import { ModalContent, ModalHeader, ModalPortal } from 'components/Modal';
import { TextLoader } from 'components/TextLoader';
import { useRepeatScanning } from 'hooks/use-repeat-scanning';

import { State } from 'types/state';

import style from './success-scan-modal.scss';

const b = block(style);


type Props = {
    onClose: () => void;
}

export const SuccessScanModal: FC<Props>= ({
	onClose,
}) => {
    
	const history = useHistory();
	const { onRepeatScanning, loading } = useRepeatScanning();
	const [ error, setError ] = useState();

	const document = useSelector(({ user, documents }: State) => {
		return documents && user?.active_scan_order ? findDocumentByOperationId(documents, user.active_scan_order.id) || null : null;
	});
	
	const onScan = () => {
		onRepeatScanning().then(setError);
	};

	const onPrint = async (): Promise<void> => {
		if (!document) {
			return;
		}
		history.push(getSettingsPrintURL([document.pk]));
		onClose();
	};

	const onDownload = () => {
		if (!document) {
			return;
		}
		window.open(document.pdf_url, '_blank')?.focus();
		onClose();
	};
    
	return (
		<ModalPortal
			id='success-scan-modal'
			isOpen={true}
			className={b()}
		>
			<ModalHeader id='success-scan-modal' close={onClose} isOpen={true}>
                Успешно отсканировано
			</ModalHeader>
			<ModalContent size='sm' id='success-scan-modal'>
				{error && <Alert type='error'>{error}</Alert>}
				Пожалуйста, достаньте документ из сканера.<br/>
				<b>Вам доступны следующие действия:</b>
				{document ?
					<ul className={b('list', { loading })}>
						<li><ButtonLink size='sm' onClick={onDownload}>Скачать документ</ButtonLink></li>
						<li><ButtonLink size='sm' onClick={onPrint}>Отправить документ печатать</ButtonLink></li>
						<li><ButtonLink size='sm' onClick={onScan}>Продолжить сканировать</ButtonLink></li>
					</ul> :
					<TextLoader className={b('loader')} />
				}
			</ModalContent>
		</ModalPortal>
	);
};

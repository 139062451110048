import { useEffect, useState } from 'react';

export const usePaginator = (itemsPerPage: number) => {

	const [pagination, setPagination] = useState({
		start: 0,
		end: itemsPerPage,
		currentPage: 0,
	});

	useEffect(() => {
		setPagination({
			start: 0,
			end: itemsPerPage,
			currentPage: 1,
		});
	}, [itemsPerPage]);
    
	const selectPage = (data) => {
		const page = data.selected;
		setPagination({
			start: page * itemsPerPage,
			end: (page + 1) * itemsPerPage,
			currentPage: Math.max(page, 1),
		});
	};
    
	return { selectPage, pagination };
};

import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { get, setURLParams } from 'utils/fetch';

import { TYPE } from 'constants/action-types';
import { apiPath } from 'constants/api-path';

import { State } from 'types/state';
import { Statistic, StatisticPrinters } from 'types/statistic';

import { adminReceivePrinters } from './printers';

const printersStatisticActions = {
	adminPrintersStatisticReceive (data: Statistic[], printer = -1) {
		return {
			type: TYPE.ADMIN.PRINTERS.STATISTIC.RECEIVE,
			data,
			printer,
		};
	},

	changeStatisticOptions (options: StatisticPrinters) {
		return {
			type: TYPE.ADMIN.PRINTERS.STATISTIC.CHANGE_OPTIONS,
			options,
		};
	},
};

export default printersStatisticActions;


export function getPrintersStatistic(): ThunkAction<void, State, void, Action> {
	return async (dispatch, getState) => {
		try {
			const data: Statistic[] = await get(apiPath.ADMIN.STATISTIC.PRINTERS);
			dispatch(printersStatisticActions.adminPrintersStatisticReceive(data));
			if (!getState().adminPrinters.data) {
				const data = await get(apiPath.ADMIN.PRINTERS);
				dispatch(adminReceivePrinters(data));
			}
		} catch (e) {
			// @TODO
		}
	};
}

export function changePrintersStatistic(statisticPrinters: StatisticPrinters): ThunkAction<void, State, void, Action> {
	return async (dispatch) => {
		if (statisticPrinters.point !== -1) {
			const data: Statistic[] = await get(setURLParams(apiPath.ADMIN.STATISTIC.PRINTERS, { printer_id: statisticPrinters.point }));
			dispatch(printersStatisticActions.changeStatisticOptions({
				...statisticPrinters,
				data: {
					...statisticPrinters.data,
					[statisticPrinters.point]: data,
				},
			}));
		}
	};
}

import { ReactElement } from 'react';
import block from 'utils/bem-css-module';

import style from './check-mark.scss';

const b = block(style);
export function CheckMark({ className = '' }: Partial<SVGAElement>): ReactElement {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 170 170' className={b().mix(className)}>
			<path d='M76 163c-5 0-10-2-13-6L5 83c-6-7-4-18 3-23 7-6 17-5 23 2l45 57 61-84a16 16 0 0 1 27 19L90 157c-3 4-8 6-14 6z'/>
		</svg>
	);
}

export default CheckMark;

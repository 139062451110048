import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscribeToUser } from 'utils/sockets';

import { getUser } from 'actions/user';

import { State } from 'types/state';
import { User } from 'types/user';


export const useSubscribeToUser = (): User | null => {
	const user = useSelector(({ user }: State) => user);
	const { userId, centToken, centTimeToken } = useSelector(({ user }: State) => {
		return {
			userId: user?.id,
			centToken: user?.cent_token,
			centTimeToken: user?.cent_time_token,
		};
	});
	const dispatch = useDispatch();
	useEffect(() => {
		if (userId && centToken && centTimeToken) {
			subscribeToUser(() => dispatch(getUser()), userId, centToken, centTimeToken);
		}
	}, [userId, centToken, centTimeToken]);

	return user;
};

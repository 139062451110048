import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { asyncRetry, post } from 'utils/fetch';

import { TYPE } from 'constants/action-types';
import { apiPath } from 'constants/api-path';
import { YANDEX_METRIC_ID } from 'constants/common';

import { State } from 'types/state';
import { User } from 'types/user';

const userActions = {
	change: changeUser,
};

function receiveUser (data: User) {
	return {
		type: TYPE.USER.RECEIVE,
		data,
	};
}

export function changeBackUser(data: Partial<User>): ThunkAction<void, State, Partial<User>, Action<any>> {
	return async (dispatch): Promise<void> => {
		const response = await post(apiPath.USER.CHANGE, data);
		if (response.status === 200) {
			dispatch(changeUser(data));
		}
	};
}

export default userActions;

export function getFakeUser(): ThunkAction<void, State, void, Action<any>> {
	return async (dispatch, getState): Promise<void> => {
		try {
			const user = getState().user;
			if (user) {
				return;
			}
			const response = await post(apiPath.USER.CREATE_FAKE_USER);
			if (!response.ok) {
				//notification
				return;
			}
			const newUser = await response.json();

			// Неблокирующиее отправление в метрику UserID с игнорированием ошибок
			// TODO: для оптимизации можно в будущем отправлять ym итолько если старый и новый uid отличаются
			if (newUser && newUser.id) {
				// Вызов ym() асинхронно и игнорирование ошибок
				try {
					setTimeout(() => {
						ym(YANDEX_METRIC_ID, 'setUserID', newUser.id);
					}, 0);
				} catch (e) {
					console.debug('Ошибка передачи uid в метрику', e);
				}
			}

			dispatch(receiveUser(newUser));
		} catch (e) {
			//notification
		}
	};
}

export function getUser(): ThunkAction<void, State, void, Action<any>> {
	return async (dispatch, getState): Promise<void> => {
		const response = await fetch(apiPath.USER.GET, { credentials: 'same-origin', method: 'GET' });
		if (!response.ok) {
			return;
		}
		const data = await response.json();

		// Неблокирующиее отправление в метрику UserID с игнорированием ошибок
		// TODO: для оптимизации можно в будущем отправлять ym итолько если старый и новый uid отличаются
		if (data && data.id) {
			// Вызов ym() асинхронно и игнорирование ошибок
			try {
				setTimeout(() => {
					ym(YANDEX_METRIC_ID, 'setUserID', data.id);
				}, 0);
			} catch (e) {
				console.debug('Ошибка передачи uid в метрику', e);
			}
		}

		// do not overwrite geo data from Yandex Map
		if (getState().user?.coords) {
			dispatch(receiveUser(data));
		} else {
			dispatch(receiveUser({
				...data,
				coords: {
					longitude: Number(response.headers.get('x-geoip-longitude')),
					latitude: Number(response.headers.get('x-geoip-latitude')),
				},
			}));
		}
	};
}

export function changeFavoritePrinter(default_point_option: number): ThunkAction<void, State, void, Action<any>> {
	return async (dispatch): Promise<void> => {
		await asyncRetry(post, 3)(apiPath.FAVORITE_PRINTER, { default_point_option });
		dispatch(changeUser({ default_point_option }));
	};
}

export function changeUser (data: Partial<User>) {
	return {
		type: TYPE.USER.CHANGE,
		data,
	};
}

import { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { uploadDocument } from 'actions/documents';

import { BombSettings } from 'types/common';
import { Document } from 'types/document';


export function useUploadFile() {
	const dispatch = useDispatch();
	const [key, setKey] = useState(Date.now().toString());

	const onFileUpload = useCallback((settings: BombSettings) => async (e: ChangeEvent<HTMLInputElement>) => {
		await (dispatch(uploadDocument([...e.target.files || []], settings)) as unknown as Promise<Promise<Document | undefined>[]>);
		setKey(Date.now().toString());
	}, []);
	
	return { key, onFileUpload };
}

import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { MainRoot } from 'roots/main-root';
import { initSentry } from 'utils/sentry';
import { getEnvironment } from 'utils/settings';

import { ErrorPage } from 'pages/ErrorPage';
import { getStore } from 'reducers/utils';

import { Environment } from 'types/common';

import '../base.global.scss';

// disable react-dev-tools for production
if (getEnvironment() == Environment.production) {
	if (typeof window.__REACT_DEVTOOLS_GLOBAL_HOOK__ === 'object') {
		for (const [key, value] of Object.entries(window.__REACT_DEVTOOLS_GLOBAL_HOOK__)) {
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			window.__REACT_DEVTOOLS_GLOBAL_HOOK__[key] = typeof value == 'function' ? () => {
			} : null;
		}
	}
}


const store = getStore();
initSentry();
const root = createRoot(document.querySelector('#app')!);
root.render(
	<Provider store={store}>
		<BrowserRouter>
			<ErrorBoundary fallback={<ErrorPage />}>
				<MainRoot />
			</ErrorBoundary>
		</BrowserRouter>
	</Provider>,
);

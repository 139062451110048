import { getAvailablePrinters, getPrintersByColorOption } from 'utils/point';

import { DEFAULT_PRINT_OPTIONS_FOR_PRINTER } from 'constants/default';
import { COLOR_OPTION_TEXT, DUPLEX_OPTION_TEXT, PRINT_ERRORS } from 'constants/text';

import { EmptyObject } from 'types/common';
import { OptionsColorType, OptionsDoublePageType, Printer, PrinterStatus } from 'types/point';
import { PrintOptionsForPrinter } from 'types/print';

import { capitalizeFirstLetter, choosePluralForm } from './common';
import { getPrintOptionsFromLocalStorage } from './local-storage';


export type OptionsColor = {
	[cup_name in string] : PrinterStatus.inaccessible | PrinterStatus.active
}

/**
 * Возвращает опции цветности для точки - только ч/б или цветная
 * @param printers
 */
export function getOptionsColor(printers: Printer[]) {
	const initialOptions = {} as OptionsColor | EmptyObject;
	return printers.reduce((options, { printer_options_color, status }) => {
		printer_options_color.forEach((option) => {
			if (status === PrinterStatus.disabled || ![OptionsColorType.bw, OptionsColorType.colorful].includes(option.cup_name)) {
				return;
			}
			if (!options[option.cup_name] || status === PrinterStatus.active) {
				options[option.cup_name] = status;
			}
		});
		return options;
	}, initialOptions);
}

type OptionsTwoSides = {
	[pk in OptionsDoublePageType]: PrinterStatus.disabled | PrinterStatus.active
}

/**
 * Возвращает доступные опции двухсторонней печати
 * @param printers
 */
export function getOptionsTwoSides(printers: Printer[]) {
	const initialOptions = {} as OptionsTwoSides | EmptyObject;
	const options = printers.reduce((options, { printer_options_double_page, status }) => {
		printer_options_double_page.forEach((option) => {
			if (status === PrinterStatus.inaccessible) {
				return;
			}
			if (!options[option.pk] || status === PrinterStatus.active) {
				options[option.pk] = status;
			}
		});
		return options;
	}, initialOptions);
	if (Object.keys(options).length === 0) {
		return {
			[OptionsDoublePageType.singleSide]: PrinterStatus.inaccessible, 
		};
	}
	return options;
}

export function getColorOptionsChangeError(
	pageCount: number,
	options: PrintOptionsForPrinter,
	printers: Printer[],
) {
	const printersByColor = getPrintersByColorOption(options.color_option, printers);
	if (printersByColor.length === 0) {
		return `${COLOR_OPTION_TEXT[options.color_option]} не поддерживается на данный момент.`;
	}
	if (!printersByColor.some(printer => printer.printer_options_double_page
		.some(({ pk }) => pk === options.duplex_option),
	)) {
		return `${DUPLEX_OPTION_TEXT[options.duplex_option]} печать недоступна для выбранного цвета.`;
	}
	return getPaperCountError(pageCount, options, printers);
}

export function getPaperCountError(
	pageCount: number,
	options: PrintOptionsForPrinter,
	printers: Printer[],
) {
	const printersDuplex = getAvailablePrinters(printers, pageCount, { ...options, duplex_option: OptionsDoublePageType.twoSide });
	if (printersDuplex.length > 0) {
		return PRINT_ERRORS.CHOOSE_DUPLEX;
	}
	const printersBw = getAvailablePrinters(printers, pageCount, { ...options, color_option: OptionsColorType.bw });
	if (printersBw.length > 0) {
		return PRINT_ERRORS.CHOOSE_BW;
	}
	const printersDuplexBw = getAvailablePrinters(printers, pageCount, {
		...options,
		color_option: OptionsColorType.bw,
		duplex_option: OptionsDoublePageType.twoSide,
	});
	if (printersDuplexBw.length > 0) {
		return PRINT_ERRORS.CHOOSE_BW_DUPLEX;
	}
	return PRINT_ERRORS.CHANGE_ADDRESS;
}

export function getOptionsDescription({ duplex_option,color_option, copies }: PrintOptionsForPrinter) {
	const copiesN = copies || 1;
	return capitalizeFirstLetter(`${DUPLEX_OPTION_TEXT[duplex_option]} ${COLOR_OPTION_TEXT[color_option]}, ${copiesN} ${choosePluralForm(copiesN, ['копия', 'копии', 'копий'])}`.toLowerCase());
}

export function getPrintOptionsForDocument(pk: number) {
	return getPrintOptionsFromLocalStorage(pk) || DEFAULT_PRINT_OPTIONS_FOR_PRINTER;
}

export function getPaperCountText(count: number) {
	return `${count} ${choosePluralForm(count, ['лист', 'листа', 'листов'])} бумаги`;
}

/**
 * Возвращает возможна ли печать с pk: OptionsDoublePageType при выбранном цвете
 * @param printers
 * @param pages_count
 * @param color_option - выбранный цвет
 * @param pk
 */
export function isOptionsDoublePageTypeAvailable(
	printers: Printer[], 
	pages_count: number,
	color_option: OptionsColorType, 
	pk: OptionsDoublePageType,
) {
	return getAvailablePrinters(printers, pages_count, { copies: 1, duplex_option: pk, color_option })
		.some(printer => printer.printer_options_double_page.find((option => option.pk === pk)));
}

export function getAvailableDoublePageOptions(options: OptionsDoublePageType[]) {
	let text = '';
	if (options.length === 0) {
		return '';
	}
	if (options.find(option => option === OptionsDoublePageType.singleSide)) {
		text += 'Односторонняя';
	}
	if (
		options.find(option => option === OptionsDoublePageType.twoSide) ||
		options.find(option => option === OptionsDoublePageType.twoSideTop)
	) {
		text += text ? ' и двусторонняя' : 'Двусторонняя';
	}
	text += ' печать';
	return text;
}

import { ReactElement } from 'react';
import block from 'utils/bem-css-module';

import { ReactComponent as DocumentType } from 'components/Icon/svg/document-type.svg';

import style from './document-loading.scss';


const b = block(style);

export type DocumentLoadingProps = {
    className?: string;
}
export const DocumentLoading = ({
	className = '',
}: DocumentLoadingProps): ReactElement => {
	return (
		<div className={b().mix(className)}>
			<DocumentType />
			<div className={b('line')} />
			<div className={b('line')} />
			<div className={b('line')} />
		</div>
	);
};

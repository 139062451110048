import { ChangeEvent, FC, useCallback } from 'react';
import block from 'utils/bem-css-module';
import { choosePluralForm } from 'utils/common';

import { FileButton } from 'components/FileButton';
import { Input } from 'components/Input';
import { ModalContent, ModalHeader, ModalPortal } from 'components/Modal';

import { BombSettings } from 'types/common';

import style from './several-page-modal.scss';

const b = block(style);


type Props = {
	isOpen: boolean;
	onClose: () => void;
	set: (options: BombSettings) => void;
	options: {x: number; y: number};
	onConfirm: (e: ChangeEvent<HTMLInputElement>) => void;
	fileInputKey: string;
}

export const SeveralPageModal: FC<Props> = ({
	isOpen,
	onClose,
	onConfirm,
	set,
	options,
	fileInputKey,
}) => {
	const setX = useCallback((e): void => {
		set({ ...options, x: parseInt(e.target.value) });
	}, [options, set]);

	const setY = useCallback((e): void => {
		set({ ...options, y: parseInt(e.target.value) });
	}, [options, set]);

	const totalCount = options.x * options.y || 1; // options.x * options.y can be NaN

	return(
		<ModalPortal
			id='several-page-modal'
			isOpen={isOpen}
			className={b()}
		>
			<ModalHeader id='several-page-modal' close={onClose} isOpen={isOpen}>
				Настройки
			</ModalHeader>
			<ModalContent size='sm' id='several-page-modal'>
				Выберите, сколько страниц должно вмещаться по горизонтали и по вертикали.
				<br/>
				Сейчас на одном листе бумаги будет {
					choosePluralForm(totalCount, ['размещена', 'размещены', 'размещено'])
				} {totalCount} {
					choosePluralForm(totalCount, ['страница', 'страницы', 'страниц'])
				}.
				<div className={b('options')}>
					<img
						src='/static/img/bomb.svg'
						alt=''
						width='186'
						height='224'
						className={b('bomb-img')}
					/>
					<div className={b('options-right')}>
						<Input
							type='number'
							value={options.x}
							onChange={setX}
							min='1'
							max='16'
							label='По вертикали:'
							id='several-page-modal-x-input'
							disabled={!isOpen}
						/>
						<Input
							type='number'
							value={options.y}
							onChange={setY}
							min='1'
							max='16'
							label='По горизонтали:'
							id='several-page-modal-y-input'
							disabled={!isOpen}
						/>
						<FileButton
							className={b('options-button')}
							onChange={onConfirm}
							id={`several-page-modal-upload-button-${fileInputKey}`}
							size='m'
							disabled={!isOpen || !(options.x || options.y)}
							ymEventName='document-upload-button-click'
						>
							Загрузить
						</FileButton>
					</div>
				</div>
			</ModalContent>
		</ModalPortal>
	);
};

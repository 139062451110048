import { FC, Suspense } from 'react';
import { useSelector } from 'react-redux';
import block from 'utils/bem-css-module';

import { DefaultPoint } from 'components/DefaultPoint';
import { DocumentsBlock } from 'components/Documents/documents-block';
import { Instruction } from 'components/Instruction';
import { Link } from 'components/Link';
import { useCurrentPoint } from 'hooks/use-current-point';
import { useScreenSize } from 'hooks/use-screen-size';
import { useScrollToTop } from 'hooks/use-scroll-to-top';
import { MapWithQrLazy } from 'pages/Map';

import { Show } from 'types/map';
import { State } from 'types/state';

import style from './first-page.scss';

const b = block(style);


const MAX_SCREEN_SIZE_WITH_MAP = 1044;
const MAX_SCREEN_SIZE_WITH_ROW_INSTRUCTION = 616;

export const FirstPage: FC = () => {
	useScrollToTop();

	const user = useSelector(({ user }: State) => user);

	const pointPk = user?.default_point_option || window.defaultPointOption;
	const point = useCurrentPoint(pointPk);
	const { width } = useScreenSize();
	const orientation = width < MAX_SCREEN_SIZE_WITH_ROW_INSTRUCTION ? 'column' : 'row';

	return (
		<div className={b()}>
			<div className={b('container', { point: Boolean(pointPk) })}>
				<h1 className={b('heading')}>Печать документов</h1>
				<h2 className={b('next-to-home')}>рядом с домом</h2>
				<DefaultPoint withPrice={true} point={point} withLink={width < MAX_SCREEN_SIZE_WITH_MAP} />
				{!window.Telegram && (
					<Instruction
						className={b('instruction')}
						orientation={orientation}
						withPlaceSelection={!pointPk}
					/>)
				}
				<DocumentsBlock
					className={b('documents')}
					documentsPerPage={9}
				/>
			</div>
			{width > MAX_SCREEN_SIZE_WITH_MAP && (
				<div className={b('map')}>
					<Suspense fallback={<div/>}>
						<MapWithQrLazy show={Show.all} />
					</Suspense>
					<Link className={b('map-button')} url='/printers' target='_self'>
						Открыть карту
					</Link>
				</div>
			)}
		</div>
	);
};

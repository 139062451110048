import { GeoObjectCollection } from 'yandex-maps';

export enum Show {
	bwPrinters = 'bwPrinters',
	colorfulPrinters = 'colorfulPrinters',
	printers = 'printers',
	all = 'all',
	copy = 'copy',
	bwCopy = 'bwCopy',
	colorfulCopy = 'colorfulCopy',
	scanners = 'scanners',
}

export enum PrintType {
	all = 'print-type-all',
	ab = 'print-type-ab',
	automatic = 'print-type-auto',
}

export type StatusShow = {
	scanner?: boolean;
	bw_printers?: boolean;
	colorful_printers?: boolean;
}

export type MapPoint = {
	pk: number;
	x_map_position: number;
	y_map_position: number;
	is_whitelabel: boolean;
	status_show: StatusShow;
}


export type GeoObjects = {
	position: [number, number];
	accuracy: number;
} & GeoObjectCollection

export type MapFeatures = {
	type: 'Feature';
	id: string;
	geometry: {
		type: 'Point';
		coordinates: [number, number];
	};
	options: {
		preset: string;
		iconColor: string;
		autoPanCheckZoomRange: true;
	};
}
